import './App.css';
import HeaderLogo from './components/HeaderLogo';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";



function App() {
  const navigate = useNavigate();


  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/login")
    }, 1500);
    return () => clearTimeout(timer);
  });
  return (
    <div className="App h-screen">
      <HeaderLogo />
      <div className='h-screen'>Bienvenue</div>
    </div>
  );
}

export default App;
