import logo from '../assets/full-logo.svg'
const HeaderLogo = () => {
  return (
    <div className="bg-lightcolor flex justify-center items-center">
      <div className="rounded-b-3xl bg-radialgreen w-full h-full flex justify-center items-center">
        <img src={logo} alt='Elevate' />
      </div>
    </div>
  );
};

export default HeaderLogo;
