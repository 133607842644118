import LoginForm from "../components/LoginForm";
import HeaderLogo from "../components/HeaderLogo";

export default function Login() {
  return (
    <>
      <HeaderLogo />
      <div className="flex items-center justify-center h-full w-full">
        <LoginForm />
      </div>
    </>
  );
}
