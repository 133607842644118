"use client";
import { useState } from "react";

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    console.log("LOGIN");
    //router.push("/dashboard"); // Redirect to a placeholder dashboard
  };

  const handleCreateAccount = () => {
    console.log("REGISTER");
    // router.push("/register"); // Redirect to the create account page
  };
  return (
    <form className="w-full max-w-sm p-10 rounded-lg">
      <input
        className="w-full p-2 mb-4 border border-gray-300 rounded-lg"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        className="w-full p-2 mb-10 border border-gray-300 rounded-lg"
        placeholder="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button
        className="w-full bg-radialgreen text-lightcolor py-2 rounded-full mb-3"
        onClick={handleLogin}
        type="submit"
      >
        Login
      </button>
      <button
        className="w-full text-darkcolor bg-elements py-2 rounded-full border"
        onClick={handleCreateAccount}
      >
        Create account
      </button>
    </form>
  );
};

export default LoginForm;
